import React from "react"
import Header from "../components/header"
import {
  BUILD_TYPO_2,
} from "../components/images"
import Footer from "../components/footer"
import Hero from "../components/hero"
import { Helmet } from "react-helmet"

export default () => (
  <div>
    <Header/>
    <Helmet
      htmlAttributes={{ lang : 'el' }}
      >
      <title>Sofia Pappa - Home</title>
    </Helmet>
    <div>
      <Hero/>
      <section id="typo" className="image-block short-pad">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4">
              <img className="background-image" {...BUILD_TYPO_2}/>
            </div>
            <div className="col-md-8">
              <div className="detail-line"/>
              <h4>ΣΟΦΙΑ ΠΑΠΠΑ, ΧΗΜΙΚΟΣ ΜΗΧΑΝΙΚΟΣ</h4>

              {/*<div className="container">*/}
              {/*<div className="row">*/}
              <div className="col-md-6">
                <p>
                  Ονομάζομαι Σοφία Παππά, κατάγομαι από την Πάτρα και αποφοίτησα από το τμήμα Χημικών Μηχανικών του
                  Πανεπιστημίου Πατρών.
                </p>
                <p>
                  Με την ολοκλήρωση των μεταπτυχιακών μου σπουδών στο τμήμα Επιστήμης και Τεχνολογίας Τροφίμων και
                  Διατροφή του Ανθρώπου του Γεωπονικού Πανεπιστημίου Αθηνών απέκτησα γνώσεις σε σχέση με τον ποιοτικό
                  έλεγχο και την ασφάλεια τροφίμων.
                </p>
                <p>
                  Η σπουδές μου (B.S., M. Eng., M/Sc, UCL ), η προϋπηρεσία μου, καθώς και ο ενθουσιασμός μου για τον
                  ερευνητικό τομέα και την προώθηση προϊόντων αποτελούν εγγυήσεις γα την ικανότητα μου να δουλεύω σε ένα
                  απαιτητικό και ταχέα αναπτυσσόμενο περιβάλλον.
                </p>
              </div>
              <div className="col-md-6">
                <div className="wide-feature">
                  <h4 style={{ fontWeight: 900 }}>
                    Υπηρεσίες
                  </h4>
                  <ul>
                    <li>Εκπόνηση περιβαλλοντικών μελετών</li>
                    <li>Επεξεργασία/ανάλυση δεδομένων</li>
                    <li>Επεξεργασία/Ανάλυση Χωρικών Δεδομένων</li>
                    <li>Χημικοτεχνικές αναλύσεις και μελέτες</li>
                    <li>Εκθέσεις αποβλήτων</li>
                  </ul>
                </div>
                <div className="wide-feature">
                  <h4 style={{ fontWeight: 900 }}>
                    Συνεργασίες
                  </h4>
                  <ul>
                    <li><a href="http://www.poadep.gr/">
                      Παρατηρητήριο Οδικών Αξόνων Δυτικής Ελλάδας και Πελοποννήσου
                      (ΠΟΑΔΕΠ) </a></li>
                    <li>Μέτρον, εταιρεία σχεδιασμού και ανάλυσης, Πάτρα</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>


    </div>
    <Footer/>
  </div>
);

